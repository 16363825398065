import app from './app';
import center from './center';
import owner from './owner';
import services from './services';
import cart from './cart';
import employees from './employees';
import booking from './booking';

export default {
    app,
    center,
    owner,
    services,
    cart,
    employees,
    booking
}