import requestService from '../services/requestService';

const getOwnerCenters = query => {
  const request = {
    method: 'get',
    url: `/owner/${query.okey}/center`,
    params: {
      type: 'owner_centers',
    }
  };

  return requestService(request);
};

const getOtherCenters = query => {
  const request = {
    method: 'get',
    url: `/owner/center/${query.ckey}`,
    params: {
      type: 'owner_other_centers',
    }
  };

  return requestService(request);
}

export default {
  getOwnerCenters,
  getOtherCenters
};
