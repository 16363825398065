import api from '../api';

const centerLoading = () => ({
    type: 'CENTER_LOADING'
});

const centerSuccess = center => ({
    type: 'CENTER_SUCCESS',
    payload: center,
});

const centerFail = () => ({
    type: 'CENTER_FAIL'
});

const getCenterByKey = key => {
    return dispatch => {
        dispatch(centerLoading());
        api.get('/center/:ckey', {
            params: { ckey: key }
        }).then(res => {
            dispatch(centerSuccess(res.data));
        }).catch(err => {
            dispatch(centerFail());
        });
    }
}

export {
    getCenterByKey
}