import React from 'react';

class SpaApp extends React.PureComponent {    
    render() {
        return (
            <>
                {this.props.children}
            </>
        );
    }
}

export default SpaApp;