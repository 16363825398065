const initialState = []

const employees = (state = initialState, action) => {
    switch (action.type) {
        case 'EMPLOYEE_SUCCESS':
            state = [
                ...action.payload
            ];
        break;

        case 'EMPLOYEE_FAIL':
            state = initialState;
        break;

        case 'CLOSE_BOOKING':
            state = initialState;
        break;

        default:
        break;
    }
    
    return state;
};

export default employees;