import api from '../api';

const ownerCenterLoading = () => ({
    type: 'OWNER_CENTER_LOADING'
});

const ownerCenterSuccess = owner => ({
    type: 'OWNER_CENTER_SUCCESS',
    payload: owner,
});

const ownerCenterFail = () => ({
    type: 'OWNER_CENTER_FAIL'
});

const getOwnerCenters = key => {
    return dispatch => {
        dispatch(ownerCenterLoading());
        api.get('/owner/:okey/center', {
            params: { okey: key }
        }).then(res => {
            dispatch(ownerCenterSuccess(res.data));
        }).catch(err => {
            dispatch(ownerCenterFail());
        });
    }
}

const getOwnerOtherCenters = key => {
    return dispatch => {
        dispatch(ownerCenterLoading());
        api.get('/owner/center/:ckey', {
            params: { ckey: key }
        }).then(res => {
            dispatch(ownerCenterSuccess(res.data));
        }).catch(err => {
            dispatch(ownerCenterFail());
        });
    }
}

export {
    getOwnerCenters,
    getOwnerOtherCenters
}