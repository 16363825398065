const initialState = {
    loading: true
};
const app = (state = initialState, action) => {
    switch (action.type) {
        case 'OWNER_CENTER_LOADING':
        case 'CENTER_LOADING':
        case 'SERVICE_LOADING':
        case 'EMPLOYEE_LOADING':
        case 'CANCEL_BOOKING_REQUEST':
        case 'UPDATE_BOOKING_REQUEST':
            state =  {
                ...state,
                loading: true
            };
        break;
        
        case 'OWNER_CENTER_FAIL':
        case 'OWNER_CENTER_SUCCESS':
        case 'CENTER_SUCCESS':
        case 'CENTER_FAIL':
        case 'SERVICE_SUCCESS':
        case 'SERVICE_FAIL':
        case 'EMPLOYEE_SUCCESS':
        case 'EMPLOYEE_FAIL':
        case 'RE_INIT_BOOKING':
        case 'CLOSE_BOOKING':
        case 'CANCEL_BOOKING_SUCCESS':
        case 'CANCEL_BOOKING_FAIL':
        case 'UPDATE_BOOKING_SUCCESS':
        case 'UPDATE_BOOKING_FAIL':
            state =  {
                ...state,
                loading: false
            };
        break;

        default:
        break;
    }
    return state;
}

export default app;