const initialState = {}

const center = (state = initialState, action) => {
    switch (action.type) {
        case 'CENTER_SUCCESS':
            state = {
                ...action.payload
            };
        break;

        case 'CENTER_FAIL':
            state = {};
        break;

        default:
        break;
    }
    
    return state;
};

export default center;