const initialState = {
    centers: []
};
const owner = (state = initialState, action) => {
    switch (action.type) {
        case 'OWNER_CENTER_SUCCESS':
            state = {
                ...state,
                centers: action.payload
            }
        break;

        case 'OWNER_CENTER_FAIL':
            state = {
                ...state,
                centers: []
            }
        break;

        default:
        break;
    }
    return state;
}

export default owner;