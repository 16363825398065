const initialState = []

const services = (state = initialState, action) => {
    switch (action.type) {
        case 'SERVICE_LOADING':
            state = [];
        break;

        case 'SERVICE_SUCCESS':
            state = action.payload || [];
        break;

        case 'SERVICE_FAIL':
            state = initialState;
        break;

        case 'CLOSE_BOOKING':
            state = initialState;
        break;

        default:
        break;
    }
    
    return state;
};

export default services;